.VideoModalContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;
  height:50vh;
  width:50vw;
}

.modalLayer {
  display: flex;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(180,180,180,.5);
  z-index: 100;
}

.videoBox {
  min-height: 91%;
  min-width:98%;
  bottom: 1em;
  position: absolute;
}

.video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
   left: 0;
  top: 0;
}

.containerHolder {
  border: '2px solid #ff0000';
  height: 100vh;
  position:relative;
}

.cardHeader {
  margin-top:-.75em;
  align-self: end;
}