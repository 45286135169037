table.mainTable {
    border-collapse: collapse;
    width: 100%;
    overflow:hidden;
    margin-top: 2px;

}
table.mainTable tbody tr:nth-child(even) {
    background-color: #f2f5f9;

}

table.mainTable h5 {
    font-size: 16px;
}

table.mainTable .titleColumn {
    padding-left: 1em;
    width: 15em;
    border-bottom: 1px solid lightblue;
    border-right: 1px solid lightblue;
}

table.mainTable .titleColumnAvg {
    padding-left: 1em;
    width: 13em;
    border-bottom: 1px solid lightblue;
    border-right: 1px solid lightblue;
}


table.mainTable .subTitle{
    padding:0;
    font-size: 16px;

}


table.mainTable .valueColumn{
    padding-left: 1em;
    width:13em;
    border-bottom: 1px solid lightblue;
    border-right: 1px solid lightblue;
}

table.mainTable .valueColumnAvg{
    padding-left: 1em;
    width:8em;
    border-bottom: 1px solid lightblue;
    border-right: 1px solid lightblue;
}

.totalCostRow {
    background-color: #0e507cb9 !important;
    /* color: white; */
    color: #82b6d6;
}


.calculatedProfitRow {
    background-color: #4190c5b9 !important;
    color: white;
}

.merRow {
    background-color: aliceblue !important;
}

.merBERow{
    background-color: #0a2435 !important;
    color: white;
}

.cpoRow {
    background-color: aliceblue !important;
}

.cpoBERow {
    background-color: #0a2435 !important;
    color: white;
}